<template>
    <div id="nav_new">
      <div v-if="loggedIn && openTrip == ''">
        <router-link to="/">
        <div class="mainmenu-item">
          <i class="fa-solid fa-home"></i> Dash
        </div>
        </router-link>
        <router-link to="/trips/my">
        <div class="mainmenu-item">
        <i class="fa-solid fa-star"></i> Mijn
        </div>
        </router-link> 
        <router-link to="/trips/open">
        <div class="mainmenu-item">
        <i class="fa-solid fa-list"></i> Pool
        </div>
        </router-link>
        <router-link to="/finance">
        <div class="mainmenu-item">
        <i class="fa-solid fa-euro"></i> Geld 
        </div>
        </router-link>
        <router-link to="/settings">
        <div class="mainmenu-item">
        <i class="fa-solid fa-gear"></i> Conf 
        </div>
        </router-link>
      </div>
      <span v-if="!loggedIn">
      <router-link to="/login">Login</router-link> | 
      <router-link to="/register">Signup</router-link> 
      </span>
    </div>
</template>

<script>



export default {
  name:'mainmenu',

  data() {
    var selected = this.$route.path;
    // var openTrip = localStorage.getItem('opentrip');
    console.log("selected item: " + selected);
    const options =  [
      { id: '/', icon: 'fa-solid fa-home', title: 'Dash', color: '#629460', path: '/'},
      { id: '/trips/my', icon: 'fa-solid fa-star', title: 'Mijn', color: '#88498f', path: '/trips/my' },
      { id: '/trips/open', icon: 'fa-solid fa-list', title: 'Pool', color: '#ff6d01' , path: '/trips/open'},
      { id: '/finance', icon: 'fa-solid fa-euro', title: 'Geld', color: '#2274a5' , path: '/finance'},
      { id: '/settings', icon: 'fa-solid fa-gear', title: 'Conf', color: '#f9db69' , path: '/settings'}
    ];
    return {selected, options}
  },
  computed: {
  openTrip() {
      var ot = localStorage.getItem('opentrip');
      return ot;
    }
  }
}
</script>