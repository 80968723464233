<template>
  <div class="finance">
   <main-nav />
    <main-titlebar myclass="yellowBar" showback="true" rbutton="newcontact" icon="fas fa-address-book"/>
  
     <div class="gen-list-wrapper">
          <div v-for="contact in contacts" :key="contact.id" class="trip-row-wrapper" @click="viewContact(contact.id)"  >
            <div class="trip-row" >
              <div class="trip-col1 textAlignLeft"> {{ contact.name}}</div>
              <div class="trip-col1 textAlignRight"><i class="fa-solid fa-user"></i> {{ contact.email}}</div>
            </div>
          </div>
        </div>

  </div>
</template>

<script>
import {ref} from 'vue'
import ContactService from "../../services/contact-service";

export default {
  name:'contacts',
  data() {
    const search = ref('')
  var status = 'loading'
    var contacts;
    return { contacts,search, status }
  },
  methods: {
    viewContact(id) {
      this.$router.push('/contacts/view/' + id);
    }
  },
  computed: {
    curUserID() {
      // var tripOwner = this.trips[0]["owner"]
      var user  = this.$store.state.auth.user['userid'];
      return user;
    }
  },
  mounted() {
    var targ = this;
    ContactService.getMyContacts().then(
      (response) => {
        console.log("ContactService.getMyContacts: data loaded from: " + JSON.stringify(response));
        targ.status = response.status;
        targ.contacts = response.data;
      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu_new.vue').default,

  }
}
</script>
